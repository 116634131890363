import React, { Suspense, useEffect, useState } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import './scss/style.scss';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { ToastContextProvider } from './ToastContext';
import getData from './components/data/getData';
import 'devextreme/dist/css/dx.light.css';
import config from 'devextreme/core/config';
import { licenseKey } from './devextreme-license';

config({licenseKey: licenseKey})

//import FontAwesome fileExt
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faCircle, faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import {
  faCheckSquare,
  faCoffee,
  faList,
  faGauge,
  faFolderOpen,
  faFolderClosed,
  faPersonChalkboard,
  faChalkboardUser,
  faGears,
  faChartLine,
  faUsers,
  faPerson,
  faPersonCircleCheck,
  faSave,
  faEdit,
  faTrash,
  faExclamationTriangle,
  faBoxArchive,
  faLightbulb,
  faChevronRight,
  faCheckDouble,
  faCircleInfo,
  faHome,
  faFolder,
  faFile,
  faCommentsDollar
} from '@fortawesome/free-solid-svg-icons';
import {IntlProvider} from "react-intl";
import Cookie from "js-cookie";

library.add(fab, faCheckSquare, faCoffee, faList, faGauge, faFolderOpen, faFolderClosed, faPersonChalkboard, faChalkboardUser, faGears, faChartLine, faUsers, faPerson, faPersonCircleCheck, faSave, faEdit, faTrash, faExclamationTriangle, faBoxArchive, faCircle, faCircleCheck, faLightbulb, faChevronRight, faCheckDouble, faCircleInfo, faHome, faFolder, faFile);

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const SlideshowModel = React.lazy(() => import('./views/slideshows/SlideshowModel'));
const SlideType = React.lazy(() => import('./views/slideshows/SlideType'));
const Slideshow = React.lazy(() => import('./views/slideshows/Slideshow'));

const CheckReport =  React.lazy(() => import('./views/checks/CheckReport'));

function App() {
  const [locale, setLocale] = useState(navigator.language.split('-')[0]);
  const [reload, setReload] = useState(false);


  useEffect(() => {
    const handleLanguageChanged = (newLocale) => {
      setLocale(newLocale);
    };
    _getAccountTheme(false)
    i18n.on('languageChanged', handleLanguageChanged);

    return () => {
      i18n.off('languageChanged', handleLanguageChanged);
    };
  }, [reload]);

  global.getAccountTheme = function getAccountTheme(refresh) {
    _getAccountTheme(refresh)
  }

  function _getAccountTheme(reload){
    const accountTheme = localStorage.getItem("accountTheme")

    if(typeof accountTheme === "undefined"
      || accountTheme === null
      || !Cookie.get('token')
    )
    {
      //console.log("NO THEME")
      localStorage.setItem("accountTheme", JSON.stringify('light'))
    }
    else{
      //console.log("THEME OK",accountTheme)
      getData('/api/accountThemes_activated', 'GET')
        .then((data) => {
          setAccountTheme(data.content)
          if(reload)  window.location.reload();
        })
    }

  }

  function setAccountTheme(name){
    localStorage.setItem("accountTheme", JSON.stringify(name))

    name = name.replace(/"/g, "");
    if (name === "dark") {
      document.body.style.backgroundColor = "#666";
    } else if (name === "light") {
      document.body.style.backgroundColor = "#FFFFFF";
    } else if (name === "pink") {
      document.body.style.backgroundColor = "#d8b7d5";
    } else if (name === "purple") {
      document.body.style.backgroundColor = "#7c6fad"
    } else if (name === "blue") {
      document.body.style.backgroundColor = "#cdebfc"
    } else if (name === "green") {
      document.body.style.backgroundColor = "#b2d7b9"
    } else if (name === "yellow") {
      document.body.style.backgroundColor = "#ffdfb2"
    } else if (name === "orange") {
      document.body.style.backgroundColor = "#fad3b5"
    } else if (name === "red") {
      document.body.style.backgroundColor = "#b0524d"
    }
  }

  return (
    <IntlProvider locale={locale}>
      <I18nextProvider i18n={i18n}>
        <ToastContextProvider>
          <HashRouter>
            <Suspense fallback={loading}>
              <Routes>
                <Route exact path="/login" name="Login Page" element={<Login />} />
                {/*<Route exact path="/logout" name="Logout" element={<Logout/>}/>*/}
                <Route exact path="/register" name="Register Page" element={<Register />} />
                <Route exact path="/404" name="Page 404" element={<Page404 />} />
                <Route exact path="/500" name="Page 500" element={<Page500 />} />

                <Route path="*" name="Boards" element={<DefaultLayout />} />

                <Route exact path="/slideshowModels/:id/:name" name="SlideshowModel" element={<SlideshowModel />} />
                <Route exact path="/slideTypes/:id/:name" name="SlideTypes" element={<SlideType />} />
                <Route exact path="/slideshow/:id/:name" name="Slideshow" element={<Slideshow />} />

                <Route exact path="/checkReport/:id" name="CheckReport" element={<CheckReport/>} />
              </Routes>
            </Suspense>
          </HashRouter>
        </ToastContextProvider>
      </I18nextProvider>
    </IntlProvider>
  );
}

export default App;
