import React, { createContext, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";

export const ToastContext = createContext({
  toastMessages: [],
  addToastMessage: (type, message) => {},
  removeToastMessage: () => {},
  resetToastMessages: () => {},
});

export const ToastContextProvider = ({ children }) => {
  const [toastMessages, setToastMessages] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const addToastMessage = (type, message) => {
    const toastMessage = { type, message, id: uuid() };
    setToastMessages([...toastMessages, toastMessage]);

    // Supprime automatiquement le message toast après 5 secondes
    setTimeout(() => {
      removeToastMessage(toastMessage.id);
    }, 5000);
  };

  const removeToastMessage = (id) => {
    setToastMessages(toastMessages.filter((toast) => toast.id !== id));
  };

  const resetToastMessages = () => {
    setToastMessages([]);
  };

  // Supprime tous les messages toast du stockage local lorsque le composant est monté
  useEffect(() => {
    localStorage.removeItem("toastMessages");
  }, []);

  // Stocke les messages toast dans le stockage local lorsque le tableau de messages toast est mis à jour
  useEffect(() => {
    localStorage.setItem("toastMessages", JSON.stringify(toastMessages));
  }, [toastMessages]);

  // Réinitialise les messages toast lorsque l'utilisateur se connecte
  useEffect(() => {
    if (isLoggedIn) {
      // resetToastMessages();
    }
  }, [isLoggedIn]);

  // Ajoutez ici la logique pour mettre à jour isLoggedIn lorsque l'utilisateur se connecte

  return (
    <ToastContext.Provider value={{ toastMessages, addToastMessage, removeToastMessage, resetToastMessages }}>
      {children}
    </ToastContext.Provider>
  );
};
